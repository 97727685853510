import Script from 'next/script'

const USAToday = () => {
  return (
    <Script
      id="usaToday"
      strategy="lazyOnload"
      dangerouslySetInnerHTML={{
        __html: `
            'use strict';
            (function cookieInterceptInit() {
              try {
                function cookieNeedsDomainOverride(cookieName) {
                  return cookieName !== '_ga';
                }
                function updateCookieOptions(options) {
                  if (typeof options === 'object' && cookieNeedsDomainOverride(options.name)) {
                    delete options.domain;
                  }
                }
                const doc = document,
                  cookieDescriptor = Object.getOwnPropertyDescriptor(Document.prototype, 'cookie'),
                  objectDefinePropertyDescriptor = Object.getOwnPropertyDescriptor(Object, 'defineProperty'),
                  objectDefinePropertyValue = objectDefinePropertyDescriptor?.value,
                  cookieStore = window.cookieStore,
                  cookieStoreSet = cookieStore?.set,
                  cookieStoreDelete = cookieStore?.delete;
                let cookieDescriptorGet = cookieDescriptor?.get,
                  cookieDescriptorSet = cookieDescriptor?.set;
                if (cookieDescriptorSet && cookieDescriptorGet && cookieDescriptor?.configurable) {
                  Object.defineProperty(doc, 'cookie', {
                    configurable: true,
                    enumerable: true,
                    get: function cookieProxyGet() {
                      return cookieDescriptorGet.call(doc);
                    },
                    set: function cookieProxySet(value) {
                      if (typeof value === 'string') {
                        try {
                          value = value.trim();
                          const equalsIndex = value.indexOf('='),
                            name = equalsIndex > -1 ? value.slice(0, equalsIndex)?.trim() : '',
                            semiIndex = value.indexOf(';') + 1,
                            lcvalue = semiIndex > 0 && value.slice(semiIndex).toLowerCase();
                          if (lcvalue && cookieNeedsDomainOverride(name)) {
                            const domainIndex = lcvalue.indexOf('domain=');
                            if (domainIndex > -1) {
                              const startIndex = semiIndex + domainIndex,
                                nextSemiIndex = value.indexOf(';', startIndex);
                              value = value.slice(0, startIndex) + (nextSemiIndex > -1 ? value.slice(nextSemiIndex) : '');
                            }
                          }
                          if (!lcvalue || lcvalue.indexOf('secure') < 0) {
                            value += ';secure';
                          }
                        } catch (err) {}
                      }
                      cookieDescriptorSet.call(doc, value);
                    }
                  });
                  if (objectDefinePropertyValue && objectDefinePropertyDescriptor.configurable) {
                    objectDefinePropertyDescriptor.value = function objectProxyDefineProperty(obj, prop, descriptor) {
                      if (obj === doc && prop === 'cookie' && typeof descriptor === 'object') {
                        if (descriptor.get) {
                          cookieDescriptorGet = descriptor.get;
                        }
                        if (descriptor.set) {
                          cookieDescriptorSet = descriptor.set
                        }
                        return obj;
                      } else {
                        return objectDefinePropertyValue.call(this, obj, prop, descriptor);
                      }
                    };
                    Object.defineProperty(Object, 'defineProperty', objectDefinePropertyDescriptor);
                  }
                  if (cookieStoreSet) {
                    cookieStore.set = function cookieStoreProxySet(options) {
                      updateCookieOptions(options);
                      return cookieStoreSet.apply(cookieStore, arguments);
                    };
                  }
                  if (cookieStoreDelete) {
                    cookieStore.delete = function cookieStoreProxyDelete(options) {
                      updateCookieOptions(options);
                      return cookieStoreDelete.apply(cookieStore, arguments);
                    };
                  }
                }
              } catch (err) {}
            })();
            `
      }}
    />
  )
}

export { USAToday }
