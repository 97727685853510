type Code = string

const PUBLISHER_CODES = {
  stacksocial: 'www',
  tmz: 'TMZ',
  usatoday: 'usatoday'
}

const PROPRIETARY_CODE_LIST = ['www', 'joyus', 'citizengoods', 'skillwise']

export const isProprietary = (code: string) =>
  PROPRIETARY_CODE_LIST.includes(code)

export const isStackSocial = (code: Code) =>
  code === PUBLISHER_CODES.stacksocial

export const isTMZ = (code: Code) => code === PUBLISHER_CODES.tmz

export const isUsaToday = (code: Code) => code === PUBLISHER_CODES.usatoday
