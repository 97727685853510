export const SSR_CART_UPSELLS_LINK = 'ssr_cart_upsells_link'
export const HIDE_BBB_LOGO = 'hide_bbb_logo'
export const CART_EXPRESS_CHECKOUT = 'cart_express_checkout'
export const DISABLE_LOYALTY_CREDIT = 'disable_loyalty_credit'
export const TRUST_BANNER_CHECKOUT = 'trust_banner_checkout_for_publisher'
export const APPLY_SALE_CONDITIONS_CHECK = 'apply_sale_conditions_check'
export const OPTIMIZELY_REVENUE_TRACK = 'optimizely_revenue_track'
export const GLADLY_CHAT = 'gladly_chat'
export const HIDE_FOOTER_NEWSLETTER_FORM = 'hide_footer_newsletter_form'

export const PUBLISHER = 'publisher'
