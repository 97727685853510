/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-next-line no-use-before-define
import { ReactElement, useEffect, useState } from 'react'
import { Amplify } from 'aws-amplify'

import Head from 'next/head'
import Router from 'next/router'
import Error from 'next/error'
import { AppProps } from 'next/app'

import { ThemeProvider } from 'emotion-theming'

import { isClient, isTest, isDevelopment } from '@utils/env'
import { setCookie } from '@utils/cookies'

import { OPTIMIZELY_SNIPPET } from '@config/env'

import { CURRENT_TENANT_KEY } from '@middlewares/tenancy'

import { useApollo } from '@lib/apollo'

import theme, { Theme } from 'src/components/theme'

import analytics from '@lib/gtm/analytics'
import GTM from '@lib/gtm/gtm'
import DigiohTracking from '@lib/Digioh/DigiohTracking'

import FBPixel from '@concepts/Publisher/FacebookPixel/FBPixel'
import FacebookPixelPageView from '@concepts/Publisher/FacebookPixel/FacebookPixelPageView'

import 'notyf/notyf.min.css'
import 'swiper/css/bundle'

import ecommerceAnalytics from '../lib/gtm/events/ecommerce'
import AppProviders, { ApplicationProps } from '@providers/AppProviders'
import Signifyd from '@lib/signifyd/views/Signifyd'
import { destroyTokensAndRedirect } from '@lib/http/utils'

import { getSegmentConfig } from '@lib/segment/utils'
import { publisherTheme } from '../components/theme/publisher'

import { init as SentryInitialize, setTrackingContext } from '@config/sentry'
import { getAuthTokens } from '@config/jwt'
import { initializeApollo } from '@lib/apollo/index'

import AuthData from '@concepts/Auth/repository/AuthData'
import Optimizely from '@lib/Optimizely/Optimizely'
import { isIOSDevice } from '@utils/checkDevice'
import { UIComponentsProvider, UITheme } from '@lib/UIComponents'
import { applyOptimizely } from '@lib/Optimizely'
import { UIThemeProps } from '@lib/UIComponents/Theme/theme'
import { isStackSocial, isUsaToday } from '@concepts/Publisher/namedPublisher'
import GladApp from '@lib/Gladly/GladApp'
import { USAToday } from '@lib/USAToday/USAToday'

const OverrideNextErrorBoundary = (): JSX.Element => {
  // Next.js doesn't provide a way to override its error boundary.
  // This is a workaround to prevent it displaying know errors.
  // It has to be added to <Head> to prevent error boundary subscribing to it.
  const script = `
    window.addEventListener('error', (event) => {
      const isKnownGTMError = /b.getAll/.test(event.message)
      const isKnownGAError = /ga is not defined/.test(event.message)
      const isKnownGAGlobalError = /gaGlobal is not defined/.test(event.message)
      const isKnownFbqError = /fbq is not defined/.test(event.message)

      if (isKnownGTMError || isKnownGAError || isKnownFbqError || isKnownGAGlobalError) event.stopImmediatePropagation()
    });
  `

  return isDevelopment() ? (
    <script dangerouslySetInnerHTML={{ __html: script }} />
  ) : (
    <></>
  )
}

Amplify.configure({ ssr: true })

Router.events.on('routeChangeComplete', (url: string) => {
  analytics.page(url)
})

const Application = ({
  Component,
  pageProps
}: AppProps<ApplicationProps>): ReactElement => {
  const [viewport, setViewport] = useState(
    'width=device-width, initial-scale=1'
  )
  const { initialApolloState, hostname, errorCode, returnTo, fetchSSRUser } =
    pageProps

  let initialUser = pageProps.initialUser
  const publisher = pageProps.publisher
  const affiliate = pageProps.affiliate

  const apolloClient = useApollo(initialApolloState)
  const isOnClientSide = isClient()
  const isUSAToday = isUsaToday(publisher.code || '')

  useEffect(() => {
    if (isStackSocial(publisher.code || ''))
      applyOptimizely({ isActive: publisher.isOptimizelyEnabled })
  }, [])

  useEffect(() => {
    if (isOnClientSide) {
      SentryInitialize()
      // NOTE: Shops already sets ImpactRedirect's ClickId cookie in its
      // ApplicationController by calling ImpactRedirect::ClickId there.
      // As Shops caches the result of fetch_stack_shops_client, trying to set
      // that cookie again here, in Shops-Client, can cause problems because
      // this query string may not represent the actual user's query string.
      //
      // Please if you are certain this query string isn't being cached and it
      // represents the actual user's query string, uncomment the line below.
      //
      // impactRedirectClickId.setFromQuery(query)
    }
  }, [isOnClientSide])

  const { key, isSegmentFacebookEnabled } = getSegmentConfig(
    publisher?.integrationsSetting?.segmentFacebookTrack as boolean,
    publisher?.integrationsSetting?.segmentClientSideKey as string
  )

  useEffect(() => {
    if (isOnClientSide && !isTest()) {
      if (isIOSDevice()) {
        setViewport('width=device-width, initial-scale=1, maximum-scale=1')
      }

      const setupApp = async () => {
        const tokens = getAuthTokens({}, false)

        if (!fetchSSRUser) {
          initialUser = await AuthData.find(
            initializeApollo(initialApolloState, {
              auth: {
                accessToken: tokens.accessToken,
                refreshToken: tokens.refreshToken,
                sessionToken: tokens.sessionToken
              },
              hostname
            }),
            'cache-first'
          )
        }

        GTM.initialize({
          publisher,
          affiliate,
          user: initialUser,
          isSegmentFacebookEnabled,
          segmentKey: key
        })

        setTrackingContext(initialUser, hostname)

        ecommerceAnalytics.trackUserVisit(
          initialUser?.hasPreviousPurchase as boolean
        )
      }

      setupApp()
    }
  }, [
    isOnClientSide,
    publisher,
    affiliate,
    hostname,
    isSegmentFacebookEnabled,
    key
  ])

  if (errorCode) {
    if (errorCode === 401) {
      destroyTokensAndRedirect(returnTo)

      return <></>
    }

    return <Error statusCode={errorCode} />
  }

  setCookie(CURRENT_TENANT_KEY, hostname)

  return (
    <AppProviders
      pageProps={pageProps}
      apolloClient={apolloClient}
      publisher={publisher}
    >
      <UIComponentsProvider
        themeToExtend={
          publisherTheme(
            UITheme as unknown as Theme,
            publisher
          ) as unknown as UIThemeProps
        }
      >
        <ThemeProvider theme={publisherTheme(theme, publisher)}>
          <Optimizely
            active={
              isStackSocial(publisher.code || '') &&
              publisher.isOptimizelyEnabled
            }
            optimizelySnippet={OPTIMIZELY_SNIPPET}
          />

          <Head>
            <meta charSet="utf-8" />
            <meta name="viewport" content={viewport} />
            <OverrideNextErrorBoundary />
            {isUSAToday && <USAToday />}
          </Head>

          <DigiohTracking publisher={publisher} />

          <GladApp />

          <FBPixel
            fbPixelId={publisher.fbPixelId}
            isSegmentFacebookEnabled={isSegmentFacebookEnabled}
          />

          <FacebookPixelPageView
            isSegmentFacebookEnabled={isSegmentFacebookEnabled}
            publisherId={publisher.databaseId as number}
          >
            <Component {...pageProps} />
            <Signifyd />
          </FacebookPixelPageView>
        </ThemeProvider>
      </UIComponentsProvider>
    </AppProviders>
  )
}

export default Application
