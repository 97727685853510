import ApolloClient from 'apollo-client'
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
  IntrospectionResultData
} from 'apollo-cache-inmemory'
import { createHttpLink } from 'apollo-link-http'
import { ApolloLink, from } from 'apollo-link'
import { onError } from 'apollo-link-error'
import { GRAPHQL_URL } from '@config/env'
import { TApolloClient } from 'src/types/apollo'
import {
  updateTokens,
  getAuthTokens,
  Tokens,
  hasAuthTokens,
  hasGuestToken,
  isTrueGuestCheckout
} from '@config/jwt'
import type { ServerError } from 'apollo-link-http-common'

import { isClient } from '@utils/env'
import fetch from 'isomorphic-fetch'
import graphqlSchema from '../../generated/graphql.schema.json'
import { buildHeadersFromCookies } from '@config/cookies'
import { destroyTokensAndRedirect } from '@lib/http/utils'

const httpLink = createHttpLink({
  uri: GRAPHQL_URL,
  fetch
})

export type OptionsType = {
  auth?: Tokens
  hostname?: string
  link?: ApolloLink
  remoteIp?: string
}

const link = (auth?: Tokens): ApolloLink =>
  new ApolloLink((operation, forward) => {
    const tokens = getAuthTokens(auth)

    const cookiesAsHeader = buildHeadersFromCookies()
    if (cookiesAsHeader) {
      operation.setContext(({ headers = {} }) => ({
        headers: {
          ...headers,
          ...cookiesAsHeader
        }
      }))
    }

    if (hasAuthTokens(tokens)) {
      operation.setContext(({ headers = {} }) => ({
        headers: {
          ...headers,
          Authorization: tokens.accessToken,
          'Stack-Refresh-Token': tokens.refreshToken,
          'Stack-Session-Token': tokens.sessionToken
        }
      }))
    }

    if (hasGuestToken(tokens)) {
      operation.setContext(({ headers = {} }) => ({
        headers: {
          ...headers,
          'Guest-Token': tokens.guestToken
        }
      }))
    }

    if (isTrueGuestCheckout(tokens)) {
      operation.setContext(({ headers = {} }) => ({
        headers: {
          ...headers,
          UIDOCP: tokens.uidocp
        }
      }))
    }

    return forward(operation).map((response) => {
      const { headers } = operation.getContext().response

      updateTokens({
        accessToken: headers.get('Stack-Token'),
        refreshToken: headers.get('Stack-Refresh-Token'),
        sessionToken: headers.get('Stack-Session-Token')
      })

      return response
    })
  })

const tenantLink = (hostname?: string): ApolloLink =>
  new ApolloLink((operation, forward) => {
    if (hostname) {
      operation.setContext(({ headers = {} }) => ({
        headers: {
          ...headers,
          'X-StackCommerce-Publisher': hostname
        }
      }))
    }

    return forward(operation).map((response) => response)
  })

const remoteIpLink = (remoteIp?: string): ApolloLink =>
  new ApolloLink((operation, forward) => {
    if (remoteIp) {
      operation.setContext(({ headers = {} }) => ({
        headers: {
          ...headers,
          'X-Forwarded-For': remoteIp
        }
      }))
    }

    return forward(operation).map((response) => response)
  })

const loggedOut = onError(({ networkError }) => {
  if ((networkError as ServerError)?.statusCode === 401) {
    destroyTokensAndRedirect()
  }
})

const buildFragmentMatcher = (): IntrospectionFragmentMatcher => {
  const types = graphqlSchema.__schema.types.filter(
    (type) => type.possibleTypes
  ) as {
    kind: string
    name: string
    possibleTypes: { name: string }[]
  }[]

  return new IntrospectionFragmentMatcher({
    introspectionQueryResultData: {
      __schema: {
        types
      }
    } as IntrospectionResultData
  })
}

const create = (state = {}, options: OptionsType = {}): TApolloClient => {
  const fragmentMatcher = buildFragmentMatcher()
  const mainLink = [
    link(options.auth),
    tenantLink(options.hostname),
    remoteIpLink(options.remoteIp),
    options.link
  ]
    .filter((a): a is ApolloLink => !!a)
    .reduce((a, b) => a.concat(b))

  return new ApolloClient({
    connectToDevTools: isClient(),
    ssrMode: !isClient(),
    link: from([mainLink as ApolloLink, loggedOut, httpLink]),
    cache: new InMemoryCache({ fragmentMatcher }).restore(state)
  })
}

export default { create }
